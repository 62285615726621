import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { ErrorContainer } from "../dom/ErrorContainer";
import { TextLoader } from "../dom/TextLoader";
import { SearchInputGroup } from "../dom/SearchInputGroup";
import { PersonLoader } from "../helpers/PersonLoader";
import { DropList } from "../dom/DropList";
import { KEYS } from "../enums/KeysIndexes";
import { Entities } from "../enums/Entities";

const axios = require('axios').default;

/**
 * компонент для поиска только по связанным с Persons сущностям - Students, Wishes, Teachers, Users
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const PersonDependentDropList = props => {

    const [errors, setErrors] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [persons, setPersons] = useState([]);
    const [results, setResults] = useState([]);
    const [noResults, setNoResults] = useState(false);
    const [resultCursor, setResultCursor] = useState(0);
    const [currentItemClicked, setCurrentListItemClicked] = useState(false);
    const [query, setQuery] = useState('');

    useEffect(() => {
        loadEntities();
    }, [persons]);

    const onSearch = () => {
        setResults([]);
        setNoResults(false);
        PersonLoader(query, setPersons, setErrors, setLoading, setNoResults);
    }

    useEffect(() => {
        if (!query){
            setResults([]);
            setNoResults(false);
            return;
        }
        // const delayDebounceFn = setTimeout(() => {
        //     PersonLoader(query, setPersons, setErrors, setLoading, setNoResults);
        // }, 500);
        // return () => clearTimeout(delayDebounceFn);
    }, [query]);

    const loadEntities = () => {
        if (persons.length <= 0) {
            setLoading(false);
            return;
        }
        const url = '/api/' + props.entity + '/';
        const personsIds = persons.map(item => {
            return item['ID'];
        });
        axios.get(url, {
            params: {
                "filter": "Y",
                "person_id IN": `(${personsIds})`,
            },
        })
            .then((response) => {
                setResults(response.data);
                if (response.data.length === 0) {
                    setNoResults(true);
                }
                setLoading(false);
            })
            .catch((error) => {
                setErrors(errors => [...errors, error.response.data]);
                setLoading(false);
            });
    };

    const setResultsActiveItem = (keyCode) => {
        if (keyCode === KEYS.DOWN && persons.length) {
            setResultCursor(prevState =>
                prevState < persons.length ? prevState + 1 : prevState
            );
        } else if (keyCode === KEYS.UP && persons.length) {
            setResultCursor(prevState =>
                prevState > 0 ? prevState - 1 : prevState
            );
        } else if (keyCode === KEYS.ENTER && persons.length) {
            setCurrentListItemClicked(true);
        }
    };

    const getListItemView = (item) => {
        if (!item.person) {
            return "";
        }
        let personString = `${item.person.fio}`;
        if (item.person.birthdate) {
            personString += `, ДР ${item.person.birthdate}`;
        }
        if (item.role) {
            personString += `, роль: ${item.role.name}`;
        }
        return personString;
    };

    return (
        <Fragment>
            {errors.length > 0 ? <ErrorContainer arErrors={errors} /> : ""}
            <SearchInputGroup
                disabled={isLoading}
                isLoading={isLoading}
                value={query}
                onSearch={onSearch}
                onChange={(value) => setQuery(value)}
                onKeyDown={e => setResultsActiveItem(e.keyCode)}
                placeholder={props.placeholder ? props.placeholder : "быстрый поиск"}
                aria-label={props.placeholder ? props.placeholder : "быстрый поиск"} />
            <DropList
                resultCursor={resultCursor}
                currentItemClicked={currentItemClicked}
                noResults={noResults}
                getListItemView={getListItemView}
                results={results}
                entity={props.entity} />
        </Fragment>
    );
};

PersonDependentDropList.propTypes = {
    placeholder: PropTypes.string,
    entity: PropTypes.string,
};
