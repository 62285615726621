import {Pages}                   from "./Pages";
import React                     from 'react';
import ReactDOM                  from "react-dom";
import {PersonDependentDropList} from "../components/PersonDependentDropList";
import {PersonDependentElements} from "../enums/PersonDependentElements";

export class Users extends Pages {

    initPageHandlers(params) {
        super.initPageHandlers();

        ReactDOM.render(
            <PersonDependentDropList
                entity={PersonDependentElements.USER}/>,
            document.getElementById('list-search-form-component'),
        );
    }

}
